body {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  height: 100%;
  width: 100%;
}

.join-header {
  display: flex;
  justify-content: center;
}

.join-header h2{
  margin: 0 0 10px;
}

.join-container {
  height: 80vh;
  width: 80vw;
  position: relative;
}

.join-btn {
  all: unset;
  width: 150px;
  padding: 10px 5px;
  background: cornflowerblue;
  color: white;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.join-actions {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.join-actions button {
  all: unset;
  border: 1px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: white;
  margin: 8px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.call-container {
  height: calc(100vh - 100px);
  width: 95vw;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
}

/*1 user*/
.call-container.grid-1 .media-container {
  grid-area: 1/1/3/5;
}

/*2 users*/
.call-container.grid-2 .media-container:first-child {
  grid-area: 1/1/3/3;
}

.call-container.grid-2 .media-container:nth-child(2) {
  grid-area: 1/3/3/5;
}

/*3 users*/
.call-container.grid-3 .media-container:first-child {
  grid-area: 1/1/3/4;
}

.call-container.grid-3 .media-container:nth-child(2) {
  grid-area: 1/4/2/5;
}

.call-container.grid-3 .media-container:nth-child(3) {
  grid-area: 3/4/2/5;
}

/*4 users*/
.call-container.grid-4 .media-container:first-child,
.call-container.grid-4 .media-container:nth-child(3) {
  grid-column: 1/3;
}

.call-container.grid-4 .media-container:nth-child(2),
.call-container.grid-4 .media-container:nth-child(4) {
  grid-column: 3/5;
}

/*5 users*/
.call-container.grid-5 {
  grid-template-rows: 3fr 1fr;
}

.call-container.grid-5 .media-container:first-child {
  grid-area: 1/1/2/5;
}

.call-container.grid-5 .media-container:nth-child(2) {
  grid-area: 2/1/3/2;
}

.call-container.grid-5 .media-container:nth-child(3) {
  grid-area: 2/2/3/3;
}

.call-container.grid-5 .media-container:nth-child(4) {
  grid-area: 2/3/3/4;
}

.call-container.grid-5 .media-container:nth-child(5) {
  grid-area: 2/4/3/5;
}

.media-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #747474;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 20px;
  grid-column: span 4;
}

.hidden {
  visibility: hidden;
  position: absolute;
}

.user {
  color: navajowhite;
}

.user.top {
  position: absolute;
  top: 0;
  left: 0;
}

.control-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0;
}

.control-container {
  height: 40px;
  overflow: visible !important;
  padding: 0 30px;
  border-radius: 50px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  display: flex;
  gap: 20px;
  justify-content: space-around;
}

.control-container button {
  all: unset;
  cursor: pointer;
}

.control-container button.leave-call {
  color: white;
  background: crimson;
  border-radius: 100%;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 0;
}

.leave-call {
  margin-top: -10px !important;
}

.user-details {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.78);
  color: white;
  padding: 2px 10px;
  border-radius: 20px;
  position: absolute;
  bottom: 5px;
}

.user-details svg {
  margin: 0 4px;
}

.speaking {
  transition: 0.2s;
  outline: 5px solid cadetblue;
}

.username {
  display: flex;
  align-items: center;
  position: absolute;
  color: white;
  bottom: 10px;
}
.username span {
  background: rgba(0, 0, 0, 0.76);
  padding: 4px 16px;
  border-radius: 20px;
}
.username svg {
  background: rgba(0, 0, 0, 0.76);
  padding: 4px;
  position: absolute;
  right: -40px;
  border-radius: 20px;
}

.media-container.disabled::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  /*z-index: 10;*/
}

video{
  object-fit: cover;
}

.micOff{
  margin-left: 10px;
}
